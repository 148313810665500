$(document).ready(function() {
    $('#particles').particleground({
        dotColor: '#424756',
        lineColor: '#363a49',
        parallax: false
    });

    $('pre code').each(function(i, block) {
        hljs.highlightBlock(block);
    });

    $('.hljs').parent().addClass("code-window");
    $('.hljs').before('<div class="window-bar"><div class="circles"><span class="circle circle-red"></span> <span class="circle circle-yellow"></span> <span class="circle circle-green"></span></div></div>');

    // Dynamic callouts
	$(".codex-content blockquote:contains(Attention)").addClass("callout callout-danger").attr('role', 'alert');
	$(".codex-content blockquote:contains(Danger)").addClass("callout callout-danger").attr('role', 'alert');

	$(".codex-content blockquote:contains(Warning)").addClass("callout callout-warning").attr('role', 'alert');
	$(".codex-content blockquote:contains(Notice)").addClass("callout callout-warning").attr('role', 'alert');

	$(".codex-content blockquote:contains(Info)").addClass("callout callout-info").attr('role', 'alert');
	$(".codex-content blockquote:contains(Note)").addClass("callout callout-info").attr('role', 'alert');

	$(".codex-content blockquote:contains(Hint)").addClass("callout callout-success").attr('role', 'alert');
	$(".codex-content blockquote:contains(Tip)").addClass("callout callout-success").attr('role', 'alert');
});
